import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const activeBtn = {
    border: '1px solid black',
  };

  return (
    <div className='mb-20'>
      <Button
        style={i18n.language === 'en' ? activeBtn : null}
        type='secondary'
        onClick={() => changeLanguage('en')}
      >
        🇺🇸 English
      </Button>
      <Button
        style={i18n.language === 'es' ? activeBtn : null}
        type='secondary'
        onClick={() => changeLanguage('es')}
      >
        🇪🇸 Español
      </Button>
    </div>
  );
};

export default LanguageSwitcher;
