import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AlvaroFitnessGradient from '../../assets/images/af-logo.png';
import './index.css';
import { connect } from 'react-redux';
import { logout } from '../../ducks/Auth/actions';
import { Button } from 'antd';
import { handleDrawerMenu } from '../../ducks/Global/actions';
import { GiHamburgerMenu } from 'react-icons/gi';

const NavBar = ({ auth, logout, isMobileView, isDrawerOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isRecommendationPage, setIsRecommendationPage] = useState(false);

  useEffect(() => {
    const checkRecommendationPage = () => {
      const isRecommendation = location.pathname === '/transformation-program';

      setIsRecommendationPage(isRecommendation);
    };
    checkRecommendationPage();
  }, [location]);

  const renderNavLinks = () => {
    let navLinks = [
      { key: 1, name: 'Menu', path: '/food-ordering' },
      { key: 2, name: 'Orders', path: '/orders', private: true },
      { key: 3, name: 'Orders Detail', path: '/orders-detail', private: true },
      { key: 4, name: 'Manage Meals', path: '/manage-meals', private: true },
    ];

    let nav = [
      {
        key: 1,
        name: 'Transformation Program',
        path: '/transformation-program',
      },
      {
        key: 2,
        name: '1-on-1 Coaching',
        path: '/',
      },
      {
        key: 3,
        name: 'Nutrition Quiz',
        path: '/tools/macronutrient-calculator',
      },
      {
        key: 4,
        name: 'About',
        path: '/about',
      },
    ];

    const links =
      auth.token && location.pathname === '/food-ordering' ? navLinks : nav;

    return links.map((link) => {
      return (
        <div key={link.key}>
          <NavLink className='nav-link mb-10' key={link.key} to={link.path}>
            <p>{link.name}</p>
          </NavLink>
        </div>
      );
    });
  };

  return (
    <div
      className='nav-bar'
      style={{
        backgroundColor: isRecommendationPage ? 'white' : 'white',
      }}
    >
      <div
        className='nav-bar-content'
        style={{
          justifyContent: isRecommendationPage && 'center',
        }}
      >
        <img
          className='logo'
          src={
            isRecommendationPage ? AlvaroFitnessGradient : AlvaroFitnessGradient
          }
          alt='logo'
          onClick={() => navigate('/')}
        />
        {!isMobileView && (
          <>
            <div style={{ display: 'flex', alignItems: 'spaceAround' }}>
              {renderNavLinks()}
            </div>
            <div>
              {auth.isAuthenticated ? (
                <Button type='secondary' onClick={logout}>
                  Logout
                </Button>
              ) : (
                <div className='flex align-center'>
                  <Button
                    className='landing-button'
                    type='primary'
                    onClick={() =>
                      navigate('/', { state: { scrollTo: '#home-section-6' } })
                    }
                  >
                    Get Started
                  </Button>
                </div>
              )}
            </div>
          </>
        )}
        {isMobileView && (
          <GiHamburgerMenu
            style={{ fontSize: '32px', cursor: 'pointer' }}
            onClick={() => dispatch(handleDrawerMenu(!isDrawerOpen))}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
