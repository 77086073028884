import axios from 'axios';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';

export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common['x-auth-token'] = token;
  } else {
    delete axios.defaults.headers.common['x-auth-token'];
  }
};

export const fetchData = async (url, config, body) => {
  const response = await axios.get(url, config, body);
  if (response.status >= 200 && response.status < 300) {
    return response.text().then((text) => {
      try {
        //if response is not json, will throw and error and return text
        const data = JSON.parse(text);
        return data;
      } catch (err) {
        return text;
      }
    });
    // const data = await response.isJson() ? response.json() : response;
    // return data;
  } else {
    throw response;
  }
};

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const validateEmail = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const convertMeasurement = (measurement, amount) => {
  let oz;
  let grams;
  let lb;
  let qty;

  if (measurement === 'oz') {
    oz = Number(amount);
    grams = Number((amount * 28.34952).toFixed(1));
    lb = Number((amount / 16).toFixed(1));
    qty = 0;
  } else if (measurement === 'grams') {
    oz = Number((amount / 28.34952).toFixed(1));
    grams = Number(amount);
    lb = Number((amount * 0.0022).toFixed(1));
    qty = 0;
  } else if (measurement === 'lb') {
    oz = Number((amount * 16).toFixed(1));
    grams = Number((amount * 453.592).toFixed(1));
    lb = Number(amount);
    qty = 0;
  } else if (measurement === 'qty') {
    oz = 0;
    grams = 0;
    lb = 0;
    qty = Number(amount);
  }

  return { oz: oz, grams: grams, lb: lb, qty: qty };
};

export const formatCustomer = (customer) => {
  return (
    <div style={{ display: 'grid' }}>
      <span style={{ lineHeight: '15px' }}>{customer.name}</span>
      <a style={{ lineHeight: '15px' }}>{customer.email}</a>
      <p></p>
    </div>
  );
};

export const isValidEmail = (email) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

export const addToMailChimp = async (form, tags) => {
  const url = '/api/mailchimp/subscribe';

  try {
    const response = await axios.post(url, {
      email: form.email,
      name: form.name ? capitalize(form.name) : undefined,
      tags: tags,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const feetToCentimeters = (feet, inches = 0) => {
  const totalInches = feet * 12 + inches;
  const centimeters = totalInches * 2.54;
  return Math.round(centimeters);
};

export const shortenText = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  }

  // Subtract 3 from maxLength to make room for the ellipsis (...)
  const truncatedText = text.substring(0, maxLength - 3);

  // Add the ellipsis (...) at the end
  return truncatedText + '...';
};

export const starRating = (rating) => {
  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= rating) {
        // Render a filled star if the current star is less than or equal to the rating
        stars.push(
          <AiFillStar key={i} className='star' style={{ color: 'gold' }} />
        );
      } else {
        // Render an empty star if the current star is greater than the rating
        stars.push(<AiOutlineStar key={i} className='star' />);
      }
    }
    return stars;
  };

  return <div className='star-rating'>{renderStars()}</div>;
};

export const generateImperialHeightOptions = (t) => {
  const options = [];
  for (let feet = 3; feet <= 9; feet++) {
    const maxInches = feet === 9 ? 0 : 11; // For 9 feet, allow only 0 inches
    for (let inches = 0; inches <= maxInches; inches++) {
      const key = `${feet}_${inches}`;
      options.push({
        name: t(`questions.height.options.${key}`),
        value: `${feet}'${inches}`,
      });
    }
  }
  return options;
};

export const generateMetricHeightOptions = (t) => {
  const options = [];
  for (let cm = 90; cm <= 210; cm++) {
    options.push({
      name: t('questions.height.metricOption', { cm }),
      value: `${cm}`,
    });
  }
  return options;
};

export default setAuthToken;
