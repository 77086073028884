import { React } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tabs } from 'antd';
import { capitalize } from '../../helpers/utils';
import { IoIosArrowBack } from 'react-icons/io';

export const Results = ({
  handleShowResult,
  setMacroPreference,
  renderCustomMacrosInput,
  macronutrientAnswers,
  renderProgress,
}) => {
  const { t } = useTranslation();

  const items = [
    {
      key: 'balanced',
      label: t('tabs.balanced.label'),
      explanation: t('tabs.balanced.explanation'),
    },
    {
      key: 'low-carb',
      label: (
        <>
          {t('tabs.low-carb.label')
            .split('\n')
            .map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
        </>
      ),
      explanation: t('tabs.low-carb.explanation'),
    },
    {
      key: 'high-carb',
      label: (
        <>
          {t('tabs.high-carb.label')
            .split('\n')
            .map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
        </>
      ),
      explanation: t('tabs.high-carb.explanation'),
    },
    {
      key: 'high-protein',
      label: (
        <>
          {t('tabs.high-protein.label')
            .split('\n')
            .map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
        </>
      ),
      explanation: t('tabs.high-protein.explanation'),
    },
    {
      key: 'ketogenic',
      label: t('tabs.ketogenic.label'),
      explanation: t('tabs.ketogenic.explanation'),
    },
    {
      key: 'custom',
      label: t('tabs.custom.label'),
      explanation: t('tabs.custom.explanation'),
    },
  ];

  return (
    <div className='landing-page'>
      <div className='results-container'>
        <div className='grid justify-center'>
          <p className='title text-center mb-10'>🎉 {t('results.congrats')}</p>
          <p
            className='big-subtitle mb-10'
            style={{
              width: '100%',
              maxWidth: '600px',
              textAlign: 'center',
            }}
          >
            {t('results.selectDietaryPreference')}
          </p>
          <Button
            type='secondary'
            className='landing-button gradient flex align-center mb-50 '
            onClick={() => handleShowResult(false)}
          >
            <IoIosArrowBack />
            {t('buttons.calculateAgain')}
          </Button>
        </div>

        <div className='two-column align-center'>
          {renderProgress()}
          <div className='flex'>
            <Tabs
              defaultActiveKey='balanced'
              items={items}
              onChange={(value) => setMacroPreference(value)}
              animated={false}
              tabPosition={'left'}
              size={'small'}
              type={'card'}
            />
            <div>
              <p className='small-title mb-10'>
                {capitalize(macronutrientAnswers.macroPreference)}
              </p>
              <p className='subtitle-text mb-10'>
                {
                  items.find(
                    (item) => item.key === macronutrientAnswers.macroPreference
                  ).explanation
                }
              </p>
              {renderCustomMacrosInput()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
