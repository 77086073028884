import React from 'react';
import { Progress, Input, Tabs, Button, Radio } from 'antd';
import { capitalize } from '../../helpers/utils';
import { faqData } from '../../data/faq';
import { RenderFaq } from '../Faq';
import { LeadForm } from '../Form';
import { Results } from './results';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../LanguageSwitcher';
import './index.css';

export const MacronutrientCalculatorComponent = (props) => {
  const { t, i18n } = useTranslation();

  const metricOptions = [
    {
      label: 'Imperial',
      value: 'imperial',
    },
    {
      label: 'Metric',
      value: 'metric',
    },
  ];

  const renderStat = (macro) => {
    return (
      <div>
        <p className='subtitle bold' style={{ fontSize: '26px', color: '' }}>
          {macro[0] === 'calories' ? macro[1] : `${macro[1]} g`}
        </p>
        <p style={{ fontSize: '14px', margin: 0 }}>{capitalize(macro[0])}</p>
      </div>
    );
  };

  const renderProgress = () => {
    // Filter the macros array to include only calories, protein, carbs, and fat
    let macros = Object.entries(props.results).filter(
      (r) =>
        r[0] === 'calories' ||
        r[0] === 'protein' ||
        r[0] === 'carbs' ||
        r[0] === 'fat'
    );
    // Find the total calories value
    const totalCalories = props.results.calories;

    const progressColors = {
      '0%': '#2761f1',
      '100%': '#ff00a2',
    };
    return (
      <div className='macros-progress'>
        {macros.map((macro) => {
          let percentage;
          if (macro[0] === 'calories') {
            percentage = 100;
          } else if (macro[0] === 'protein' || macro[0] === 'carbs') {
            percentage = ((macro[1] * 4) / totalCalories) * 100;
          } else if (macro[0] === 'fat') {
            percentage = ((macro[1] * 9) / totalCalories) * 100;
          }

          return (
            <div key={macro[0]} style={{ padding: '10px' }}>
              <Progress
                type='circle'
                strokeColor={progressColors}
                status='active'
                percent={percentage}
                format={() => renderStat(macro)}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const renderCustomMacrosInput = () => {
    if (props.macronutrientAnswers.isCustomMacros) {
      return (
        <div>
          <div className='custom-macros-input-container'>
            {Object.entries(props.macronutrientAnswers.customMacros).map(
              (macro) => (
                <Input
                  onChange={(e) =>
                    props.setCustomMacros(macro[0], Number(e.target.value))
                  }
                  value={props.macronutrientAnswers.customMacros[macro[0]]}
                  type='number'
                  addonAfter={`${capitalize(macro[0])} %`}
                  maxLength={2}
                />
              )
            )}
          </div>
          {props.percentageError ? (
            <p
              className='mb-20'
              style={{ textAlign: 'center', color: 'red', fontSize: '14px' }}
            >
              Macro percentages must equal 100%
            </p>
          ) : null}
        </div>
      );
    }
  };

  const formInfo = {
    title: t('form.title'),
    subtitle: t('form.subtitle'),
    buttonText: t('form.buttonText'),
    url: '/api/sendgrid/send-macro-email',
    templateId:
      i18n.language === 'en'
        ? 'd-b2486d4983194680877e78cff466177b'
        : 'd-e7c3ba285c7c4544be5b0625bf53a565',
    tags: ['Subscriber', 'Tools', '52 Recipes Ebook'],
    subject: t('form.subject'),
    source: 'macronutrients',
  };

  const style = {
    width: '100%',
    marginBottom: '50px',
  };

  return (
    <>
      {!props.showResult ? (
        <div className='questions-container'>
          <LanguageSwitcher />
          <div className={`${props.showResult ? 'mb-50' : ''}`}>
            <Radio.Group
              className='mb-20'
              options={metricOptions}
              onChange={(e) =>
                props.handleDropdownValue('unit', e.target.value)
              }
              optionType='button'
              buttonStyle='solid'
              defaultValue={props.macronutrientAnswers.unit}
            />
            <div className='two-col-input'>
              {props.renderDropdown(
                props.questionOptions.gender.options,
                props.questionOptions.gender.name,
                'gender'
              )}
              {props.renderInputField(props.questionOptions.age.name, 'age')}
            </div>
            <div className='two-col-input'>
              {props.renderInputField(
                props.questionOptions.weight.name,
                'weight'
              )}
              {props.macronutrientAnswers.unit === 'imperial'
                ? props.renderDropdown(
                    props.questionOptions.height.options,
                    props.questionOptions.height.name,
                    'height'
                  )
                : props.renderInputField(
                    props.questionOptions.height.name,
                    'height'
                  )}
            </div>
            <div className='two-col-input'>
              {props.renderDropdown(
                props.questionOptions.activityFactor.options,
                props.questionOptions.activityFactor.name,
                'activityFactor'
              )}
              <div>
                {props.renderDropdown(
                  props.questionOptions.bmrFormula.options,
                  props.questionOptions.bmrFormula.name,
                  'bmrFormula'
                )}
                {props.macronutrientAnswers.bmrFormula === 'katch-mcardle'
                  ? props.renderInputField(
                      t('questions.bodyFatPercentage.label'),
                      'bodyFatPercentage'
                    )
                  : null}
              </div>
            </div>

            {props.renderDropdown(
              props.questionOptions.goal.options,
              props.questionOptions.goal.name,
              'goal'
            )}

            <Button
              loading={props.isLoading}
              onClick={() => props.showResults()}
              size='large'
              type='primary'
            >
              {t('buttons.getMyResults')}
            </Button>
          </div>
        </div>
      ) : (
        <>
          <Results
            renderProgress={renderProgress}
            handleShowResult={props.handleShowResult}
            setMacroPreference={props.setMacroPreference}
            renderCustomMacrosInput={renderCustomMacrosInput}
            macronutrientAnswers={props.macronutrientAnswers}
          />
          <div className='mb-100'>
            <LeadForm
              goal={props.macronutrientAnswers.goal}
              gender={props.macronutrientAnswers.gender}
              results={props.results}
              macroPreference={props.macronutrientAnswers.macroPreference}
              percentageError={props.percentageError}
              formInfo={formInfo}
              step={true}
            />
          </div>
          <RenderFaq
            faqData={faqData.macronutrientCalculator}
            style={style}
            useTranslationFlag={true}
          />
        </>
      )}
    </>
  );
};
