import React from 'react';
import { Collapse } from 'antd';
import { useTranslation } from 'react-i18next';
const { Panel } = Collapse;

export const RenderFaq = ({
  faqData,
  style,
  isProductLanding,
  collapseStyle,
  useTranslationFlag,
}) => {
  console.log(useTranslationFlag);
  const { t } = useTranslation();
  return (
    <div style={style}>
      <p
        className={
          !isProductLanding
            ? 'small-title text-center mb-20'
            : 'landing-page title center-aligned mb-50'
        }
      >
        {useTranslationFlag ? t('faq.faqTitle') : 'Frequently Asked Questions'}
      </p>
      <Collapse bordered={false} style={collapseStyle}>
        {useTranslationFlag
          ? // Render from translation JSON
            Object.keys(t('faq.macros', { returnObjects: true })).map((key) => (
              <Panel
                className='collapse-text'
                header={t(`faq.macros.${key}.header`)}
                key={key}
              >
                <p className='collapse-text'>{t(`faq.macros.${key}.text`)}</p>
              </Panel>
            ))
          : // Render from faqData array
            faqData.map((item) => (
              <Panel
                className='collapse-text'
                header={item.header}
                key={item.key}
              >
                <p className='collapse-text'>{item.text}</p>
              </Panel>
            ))}
      </Collapse>
    </div>
  );
};
